import React from "react"
import { graphql, Link } from "gatsby"
import Slider from "../components/Slider"

export const query = graphql`
  query($slug: String!) {
    postsJson(slug: { eq: $slug }) {
      title
      media
      slug
      description
    }
    allPostsJson {
      edges {
        node {
          slug
        }
      }
    }

    allMediaFiles: allFile {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`

const PostTemplate = ({ data, location }) => {
  const post = data.postsJson
  const allPosts = data.allPostsJson.edges
  const { allImageContent, allMediaFiles } = data

  // See if page is opened in a modal
  let isModal = !!location.state ? location.state.modal : false

  // Get image index
  let imageIndexVar = !!location.state ? location.state.imageIndex : 0
  // Send "max" to get last image of post.
  if (imageIndexVar == "last") {
    imageIndexVar = post.media.length - 1
  }

  // Get media
  let mediaArray = []
  post.media.map(item => {
    return mediaArray.push(item)
  })

  return (
    <div className="single_post-container">
      <Link
        to="/"
        state={{
          noScroll: isModal, // noScroll == true if component is Modal.
        }}
      >
        <div className="closing_icon"></div>
      </Link>
      <Slider
        mediaData={mediaArray}
        postsData={allPosts}
        postData={post}
        currentSlug={post.slug}
        imageIndex={imageIndexVar}
        isModal={isModal}
      />
    </div>
  )
}

export default PostTemplate
