import { Link } from "gatsby"
import React, { Component } from "react"

class Slider extends Component {
  state = {
    index: this.props.imageIndex,
    slugs: "",
    slugIndex: "",
    post: this.props.postData,
    media: this.props.mediaData,
    isModal: this.props.isModal,
  }

  componentDidMount() {
    // Get all slugs and put them in an array.
    let postsData = this.props.postsData
    let slugArray = []
    postsData.map(item => {
      slugArray.push(item.node.slug)
    })

    // Find current pages index in slugArray.
    let currentSlug = this.props.currentSlug

    this.setState({
      slugs: slugArray,
      slugIndex: slugArray.indexOf(currentSlug),
    })
  }

  nextProperty = () => {
    const newIndex = this.state.index + 1
    if (newIndex < this.state.media.length) {
      this.setState({
        index: newIndex,
      })
    }
  }

  prevProperty = () => {
    const newIndex = this.state.index - 1
    if (this.state.index > 0) {
      this.setState({
        index: newIndex,
      })
    }
  }

  render() {
    const { media, post, index, slugs, slugIndex, isModal } = this.state
    const nextIndex = index + 1
    // console.log(media[index])
    return (
      <>
        <div className="arrow-container">
          {index == 0 ? (
            <Link
              to={`/${slugs[slugIndex + -1]}/`}
              state={{ imageIndex: "last", modal: isModal }}
              className={"arrow left_arrow"}
            />
          ) : (
            <div
              className="arrow left_arrow"
              onClick={() => this.prevProperty()}
            />
          )}
          {nextIndex == this.state.media.length && !!slugs[slugIndex + 1] ? (
            <Link
              to={`/${slugs[slugIndex + 1]}/`}
              state={{ imageIndex: 0, modal: isModal }}
              className={"arrow right_arrow"}
            />
          ) : (
            <div
              className="arrow right_arrow"
              onClick={() => this.nextProperty()}
            />
          )}
        </div>

        {media[index].includes(".mp4") ? (
          // Media is video
          <div className={"post__media-container"}>
            <video className="post__media" src={media[index]} autoPlay loop>
              <source type="video/mp4" />
            </video>
          </div>
        ) : (
          // Media is image
          <div className={"post__media-container"}>
            <img className={"post__media"} src={media[index]} />
          </div>
        )}
        <p className={"post__desc"}>{post.description}</p>
        <p className={"post__counter"}>
          {index + 1}/{media.length}
        </p>
      </>
    )
  }
}

export default Slider
